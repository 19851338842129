import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vue3TouchEvents from "vue3-touch-events"
import VSquircle from "vue-squircle";
import Camera from "simple-vue-camera";

const pinia = createPinia()
const app = createApp(App)

app.use(router).use(pinia).use(Vue3TouchEvents).use(VSquircle).component("camera", Camera).mount('#app')
